.graph-line {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 80px; }
  .graph-line svg {
    display: block; }
  .graph-line .line {
    stroke: #0399cd; }

.graph-line-total {
  font-weight: 100;
  font-size: 140% !important; }

.graph-line-text {
  min-height: 2rem; }

.graph-donut {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .graph-donut text {
    font-weight: 300;
    line-height: 1.1; }

.graph-donut-square {
  position: relative;
  display: block;
  padding-bottom: 100%; }
  .graph-donut-square .graph-donut {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.graph-donut-checkpoints {
  text-transform: uppercase; }

.graph-donut-mobilisation .label {
  font-size: 2.5rem; }

.graph-donut-mobilisation .completion-completed {
  fill: #58b582; }

.graph-donut-mobilisation .timespan-remaining {
  fill: transparent; }

.graph-donut-mobilisation .mobilisation-complete {
  fill: #acd036; }

.graph-donut-dark .graph-donut-mobilisation .completion-remaining {
  fill: #3a3d44; }

.graph-donut-dark .graph-donut-mobilisation .timespan-spent {
  fill: #636c72; }

.graph-donut-dark .graph-donut-mobilisation .mobilisation-complete {
  fill: #acd036; }

.graph-donut-light .graph-donut-mobilisation .completion-remaining {
  fill: #eceeef; }

.graph-donut-light .graph-donut-mobilisation .timespan-spent {
  fill: #d8dadc; }

.graph-donut-light .graph-donut-mobilisation .mobilisation-complete {
  fill: #acd036; }
