.tag-input-ctn {
  display: inline-block;
  -webkit-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.075);
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  min-height: 2.25em; }

.tag-input-ctn input {
  display: inline-block;
  float: left;
  height: 18px;
  padding: 0px;
  font-size: 13px;
  line-height: 18px;
  color: black;
  border: 0px;
  margin: 0.25em 0.25em 0.25em 0.25em; }

.tag-input-ctn input:focus {
  outline: 0;
  -webkit-box-shadow: 0px;
          box-shadow: 0px; }

.tag-input-ctn .input-tag {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: #ffffff;
  padding: 0.25rem 0.25rem;
  line-height: 1.25;
  font-size: 0.875rem;
  display: inline-block;
  float: left;
  margin: 0.25em 0.25em 0.25em 0.25em;
  border-radius: 13px;
  color: #0399cd;
  border: solid 1px #0399cd;
  white-space: nowrap; }

.tag-input-ctn .input-tag .delete-tag {
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  padding: 0px 2px; }

.tag-input-ctn .input-tag .delete-tag:hover {
  background-color: #96b4d2; }
